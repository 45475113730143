import React from 'react'

export default function HomeFigures() {
  return (
    <section className="section6">
    <div className="container">
      <h1>OUR FIGURES - DON'T LIE</h1>
      <div className="row">
        <div className="col-4 count">
          <span>560</span>
          <p>
            We Have Worked
            <br />
            With Clients
          </p>
        </div>

        <div className="col-4 count">
          <span>99%</span>
          <p>
            Positive Rate <br />
            From Customer
          </p>
        </div>
        <div className="col-4 count">
          <span>500</span>
          <p>
            Number of programmes
            <br />
            we have executed
          </p>
        </div>
      </div>
    </div>
  </section>
  )
}
