import React from 'react'
import { post } from './Posts'
import { Container } from 'react-bootstrap';
type LibraryPostData = {Post:post};
export default function LibraryPostView({ Post }:LibraryPostData) {
  return (
    <Container className='post'>

<div className="profile-img-holder">
                                      <img
                                        className="img-fluid profile-img"
                                        src={Post.profile}
                                        alt={Post.profile}
                                      
                                      />
                                       {Post.writer}
                                        <img
                                          className="img-fluid"
                                        
                                          alt="writter"
                                        />

{Post.publishedDate}
                                    </div>

        <h1>{Post.title}</h1>
        <br/>
        <img className='img-fluid' src={Post.banner} alt='post' />
        <br/>
        { Post.content }
    </Container>
  )
}
