import React from "react";
import { Container, Form } from "react-bootstrap";

export default function UnimasGetinTouch() {
  return (
    <section className="section-unimas-get">
      <div className="holder">
        <Container>
          <h1>GET IN TOUCH</h1>
          <p>
            29-2 (6), Jalan PJU 1/37, Blok H, Dataran Prima, 47301 Petaling
            Jaya, Selangor.
            <br />
            Phone: 03- 78055571, 016 - 666 0819 , 011-1 940 2538
            <br />
            Email:videvconsulting@yahoo.com
            <br />
            UNIMAS Professional Education Provider
            <br />
          </p>
          <div className="btn-holder">
            <button className="btn">Brochure</button>
            <button className="btn">Application</button>
            <button className="btn">Checklist</button>
          </div>
        </Container>
      </div>
    </section>
  );
}
