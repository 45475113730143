import React from 'react'

type offer = {
    title: string;
    description: string;
  };
  

export default function UnimasOffer() {

    const offers: offer[] = [
        {
          title: "Access Current HR - Capacity with HSRM & HSRP",
          description:
            "This program helps students particularly Working adults that wishes to continue their masters To develop an understanding of theory and practice in the overall human resource management areas; by Critically assess existing theory and practice in the field of HRM and Industrial Relations This Program helps to also Develop an ability to undertake qualitative and quantitative research on Industrial Actions and HR issues and mainly To help students prepare effectively, through broadening their knowledge, skills and ability for a career as human resource specialists",
        },
        {
          title: "Human Resource Research Review & Evaluation",
          description:
            "The program Apply knowledge about qualitative and quantitative research to an HR issues towards its impact and outcomes positively to problems in unfamiliar contexts, the program also teaches one to Identify and apply new ideas, methods and ways of thinking. This program also helps To develop the ability to sensibly solve employees and organizational issues; and            ",
        },
        {
          title: "Develop Talent Strategies & Recruitment Proceses",
          description:
            "The Program Demonstrate competence in communicating and exchanging ideas in HR  problem solving context. Additionally the program Be able to advance well-reasoned and factually supported arguments in both written work and oral presentations. This program also helps to To inculcate a sense of appreciation for innovative thinking in human resource management areas, particularly in understanding organizational theory and its application to organizational development;",
        },
        {
          title: "Forecast HR Requirements With latest IR Regulations & ER Law",
          description:
            "The program will help one to Work effectively with colleagues with diverse skills, experience levels and way of thinking. Furthermore , The program Be able to evaluate HRM related social, cultural, ethical,legal and environmental responsibilities and issues in a global context. This program also helps To provide a reliable basis for further professional or educational enhancement in the future.",
        },
       
      ];

  return (
    <section className="section-unimas-offer">
      <h1 >What UNiMAS Offer</h1>
      <h2 >Strengthen your skills and rank up your education level

By learning the  4'Core Human Resource solutions 

with our 12 Post-Graduate Module for the Next 12 Months.

with our team - GUARANTEES will help

in your everyday Work Force</h2>
      <div className="container">
        <div className="row">
          <div className="col-12 philosolpy-list">
            {offers.map((item: offer) => {
              return (
                <>
                  <div className="row wao">
                    <div className="col-12">
                        <hr className="solid line2" />
                    </div>
                    <div className="col-12">
                        <div className="row">
                        <div className="col-1 icon-center">
                      <svg
                        className="icon-list"
                        preserveAspectRatio="none"
                        data-bbox="29.5 29.5 141 141"
                        viewBox="29.5 29.5 141 141"
                        height="200"
                        width="200"
                        xmlns="http://www.w3.org/2000/svg"
                        data-type="shape"
                        role="presentation"
                        aria-hidden="true"
                        aria-label=""
                      >
                        <g>
                          <path d="M170.5 93.92v6.118c-.022 38.861-31.646 70.462-70.5 70.462h-.04c-38.874-.022-70.481-31.667-70.46-70.54.022-38.859 31.646-70.46 70.5-70.46h.04a70.046 70.046 0 0 1 28.653 6.104 3.998 3.998 0 0 1 2.025 5.281 3.997 3.997 0 0 1-5.281 2.025 62.121 62.121 0 0 0-25.401-5.41h-.035c-34.448 0-62.48 28.013-62.501 62.464-.02 34.463 28.002 62.516 62.464 62.536H100c34.447 0 62.479-28.013 62.5-62.464V93.92a4 4 0 0 1 8 0zm-87.622-3.294a4 4 0 1 0-5.656 5.656l19.95 19.95a4.001 4.001 0 0 0 2.828 1.172h.001a3.997 3.997 0 0 0 2.829-1.173l66.5-66.566a4 4 0 0 0-.003-5.657 3.999 3.999 0 0 0-5.657.003l-63.671 63.735-17.121-17.12z"></path>
                        </g>
                      </svg>
                    </div>
                    <div className="col-5">
                      <div className="normal-line">{ item.title }</div>
                    </div>
                    <div className="col-6">
                      <p className="line-detail">
                      { item.description }
                      </p>
                    </div>
                        </div>
                    </div>
                   
                  </div>
                </>
              );
            })}
          </div>
          <div className="col-12">
            <hr className="solid line2"/>
          </div>
        </div>
      </div>
    </section>
  )
}
