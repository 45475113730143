import React, { useEffect, useState } from 'react'
import { Card, Spinner } from 'react-bootstrap'
import { companyId, image_path, server } from '../../env';
import axios from 'axios';

type Card = {
    Image: string;
    Title: string;
    Description: string;
}

export default function AvailableServices() {

    const [datas, setDatas] = useState<Card[]>();
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
      axios.get(server + "/api/content/Home/Services/" + companyId)
      .then((result) => {
       console.log(result.data.data);
       setDatas(result.data.data as Card[]);
       console.log("Converted Value");
       console.log(result.data.data as Card[]);
       setLoading(false);
      })
      .catch((error) =>{
  
      });
    }, []);
    if(loading)
      return (<Spinner animation="grow" variant="primary" />);
    else
  return (
    <section className='section8'>

<Card className='section8-card'>
    <Card.Header>
    <div className='section8-card-title'>
        <div className='section8-card-title-bg'>
            <h1>Available Services</h1>
        </div>
        </div>
    </Card.Header>
    <Card.Body>
        <div className='container'>
        <div className='row'>
            { datas?.map((items: Card) => {
                return <><div className='col-12 col-sm-6 col-md-4 col-xl-4 col-xxl-4 '>
                <Card className='card-as'>
                    <Card.Header>
                   <img src={ server + image_path + items.Image} alt={items.Title} />
                    </Card.Header>
                    <Card.Body>
                        <h1>{ items.Title }</h1>
                    </Card.Body>
                    <Card.Footer><p>{ items.Description }</p></Card.Footer>
                </Card>
                    </div></>;
            }) }
            
        </div>
        </div>
    </Card.Body>
</Card>

    </section>
   
  )
}
