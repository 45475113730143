import React from 'react'

export default function UnimasBannerIntro() {
  return (
    <section className="section-unimas-intro">
        <h1>UpgradeYour Skills <br/>&  Knowledge</h1>
        <p>Executive Masters In Human Resource & Industrial Relations</p>
        <p>UNIMAS - UNIVERSITY MALAYSIA SARAWAK</p>
    </section>
  )
}
