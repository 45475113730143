import React from 'react'
import { Container } from 'react-bootstrap'

export default function UnimasLearningOutcome() {
  return (
    <section className="section-unimas-learn-outcome">
        <Container>
            <h1>Learning Outcome</h1>
            <p>Critically assess existing theory and practice in the field of HRM and Industrial Relations. Develop an ability to undertake qualitative and quantitative research on Industrial Actions and HR issues. Apply knowledge about qualitative and quantitative research to an HR issues towards its impact and outcomes. Respond positively to problems in unfamiliar contexts</p>
        </Container>
    </section>
  )
}
