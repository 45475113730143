import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { post, posts } from "./Posts";
import writter from "../../assets/img/writter.svg";
import like from "../../assets/img/like.svg";
import LibraryPostView from "./LibraryPostView";

export default function LibraryPosts() {
  const [selPost, setSelPost] = useState<post>();
  const [show, setShow] = useState<boolean>(false);

  return (
    <section className="section-library-posts">
      <Container>
        <Row>
          <Col className="all-post">
            <Container>
              <a className="">All Posts</a>
            </Container>
          </Col>
        </Row>
        <Row>


          { show ? <LibraryPostView Post={selPost!} /> : posts.map((post) => {
            return (
              <>
                <Col sm={12} className="mt-3 mb-3">
                  <Container>
                    <Row>
                      <Col>
                        <img
                          className="img-fluid side-img"
                          src={post.banner}
                          alt={post.title}
                        />
                      </Col>
                      <Col>
                        <Container>
                          <Row>
                            <Col sm={12}>
                              <Row>
                                <Col className="profile-holder">
                                  <Container>
                                    <div className="profile-img-holder">
                                      <img
                                        className="img-fluid profile-img"
                                        src={post.profile}
                                        alt={post.profile}
                                        onClick={() => {
                                            setSelPost(post);
                                            setShow(true);
                                        }}
                                      />
                                    </div>
                                    <Row>
                                      <Col className="writter" sm={12}>
                                        {post.writer}
                                        <img
                                          className="img-fluid"
                                          src={writter}
                                          alt="writter"
                                        />
                                      </Col>
                                      <Col className="date" sm={12}>
                                        {post.publishedDate}
                                      </Col>
                                    </Row>
                                  </Container>
                                </Col>
                                <Col>&nbsp;</Col>
                              </Row>
                            </Col>
                            <Col sm={12}>
                              <h1 onClick={() => {
                                            setSelPost(post);
                                            setShow(true);
                                        }}className="title">{post.title}</h1>

                              <p className="desc">{post.description} ...</p>
                            </Col>
                            <Col sm={12}>
                              <hr className="line" />
                              <Row>
                                <Col className="view_holder">
                                  <span className="views">
                                    0 views &nbsp; 0 comments
                                  </span>
                                </Col>
                                <Col className="like_holder">
                                  <img className="like" src={like} alt="like" />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </>
            );
          })}
        </Row>
      </Container>
    </section>
  );
}
