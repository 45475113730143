import React from 'react'

export default function FreeQuote() {
  return (
    <section className="section10">
    <div className="section10-bg">
        <div className='row'>
            <div className='col-6 col-xs-11 col-sm-6'>
      <div className="sec10-mid-box">
        <div className="container">
          <h1>Feel Free To Enquire About</h1>
          <h1>Any Questions You Got</h1>

        
          <button className="btn btn-sec10-box"> Free Quotation Today</button>
        </div>
      </div>
      </div>
    </div>
    </div>
  </section>
  )
}
