import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import pic1 from "../../assets/img/unimas/pic1.webp";
import pic2 from "../../assets/img/unimas/pic2.webp";
import pic3 from "../../assets/img/unimas/pic3.webp";
import pic4 from "../../assets/img/unimas/pic4.webp";
import pic5 from "../../assets/img/unimas/pic5.webp";
import pic6 from "../../assets/img/unimas/pic6.webp";
import pic7 from "../../assets/img/unimas/pic7.webp";
import pic8 from "../../assets/img/unimas/pic8.webp";
import pic9 from "../../assets/img/unimas/pic9.webp";

export default function UnimasPhotos() {
  return (
    <section className="section-unimas-photos">
      <OwlCarousel>
        <div className="item">
          <img className="img" src={pic1} alt="pic1" />
        </div>
        <div className="item">
          <img className="img" src={pic2} alt="pic1" />
        </div>
        <div className="item">
          <img className="img" src={pic3} alt="pic1" />
        </div>
        <div className="item">
          <img className="img" src={pic4} alt="pic1" />
        </div>
        <div className="item">
          <img className="img" src={pic5} alt="pic1" />
        </div>
        <div className="item">
          <img className="img" src={pic6} alt="pic1" />
        </div>
        <div className="item">
          <img className="img" src={pic7} alt="pic1" />
        </div>
        <div className="item">
          <img className="img" src={pic8} alt="pic1" />
        </div>
        <div className="item">
          <img className="img" src={pic9} alt="pic1" />
        </div>
      </OwlCarousel>
    </section>
  );
}
