import React from 'react'
import ReactPlayer from 'react-player'
import YouTube from 'react-youtube'

export default function AihraYoutube() {
  return (
   <section className="section-aihra-youtube">

    {/* <ReactPlayer src="https://youtu.be/d__yzgEHyzM" muted={true} autoplay="true">

    </ReactPlayer> */}

    <YouTube videoId='d__yzgEHyzM' />
   </section>
  )
}
