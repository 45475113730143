import React from 'react'

export default function StudioVideoIntro() {
  return (
   <section className='section-studio-video'>
     <video controls={false} muted={true} autoPlay={true} width="100%" loop={true} >
              <source
                type="video/mp4"
                src="https://video.wixstatic.com/video/13e70d_3bbd26622aef49309e5e3b358d7791d7/720p/mp4/file.mp4"
              />
            </video>
   </section>
  )
}
