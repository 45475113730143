import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function PackageDetails() {
  return (
    <section className="package-details">
      <Container>
        <h1>Join over a million happy users!</h1>
        <Row>
          <Col className="item-1">
            <div className="top-image">
              <svg
                preserveAspectRatio="xMidYMid meet"
                data-bbox="20 20 160 160"
                viewBox="20 20 160 160"
                height="200"
                width="200"
                xmlns="http://www.w3.org/2000/svg"
                data-type="shape"
                role="presentation"
                aria-hidden="true"
                aria-label=""
              >
                <g>
                  <path d="M100 20c-44.118 0-80 35.882-80 80s35.882 80 80 80 80-35.882 80-80h-80V20z"></path>
                </g>
              </svg>
            </div>
            <h2>Bronze Package</h2>
            <p>All basic package & Excluding Payroll and Talent Acquisition</p>
          </Col>
          <Col className="item-2">
            <div className="top-image">
              <svg
                preserveAspectRatio="xMidYMid meet"
                data-bbox="38.5 20 123 160"
                viewBox="38.5 20 123 160"
                height="200"
                width="200"
                xmlns="http://www.w3.org/2000/svg"
                data-type="color"
                role="presentation"
                aria-hidden="true"
                aria-label=""
              >
                <g>
                  <path
                    d="M102.887 20v117.469c32.371 0 58.613-26.296 58.613-58.734C161.5 46.296 135.258 20 102.887 20z"
                    fill="#9A9A9A"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    data-color="1"
                  ></path>
                  <path
                    d="M38.5 121.266C38.5 153.704 64.742 180 97.113 180V62.532c-32.371 0-58.613 26.296-58.613 58.734z"
                    fill="#9A9A9A"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    data-color="1"
                  ></path>
                </g>
              </svg>
            </div>
            <h2>Silver Package</h2>
            <p>
              All basic package - Includes Payroll - but Excludes Talent
              Acquisition
            </p>
          </Col>
          <Col className="item-3">
            <div className="top-image">
              <svg
                preserveAspectRatio="xMidYMid meet"
                data-bbox="20 20 160 160"
                viewBox="20 20 160 160"
                height="200"
                width="200"
                xmlns="http://www.w3.org/2000/svg"
                data-type="color"
                role="presentation"
                aria-hidden="true"
                aria-label=""
              >
                <g>
                  <path
                    d="M20 20v160c44.183 0 80-35.817 80-80S64.183 20 20 20z"
                    fill="#D1B83E"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    data-color="1"
                  ></path>
                  <path
                    d="M100 100c0 44.183 35.817 80 80 80V20c-44.183 0-80 35.817-80 80z"
                    fill="#D1B83E"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    data-color="1"
                  ></path>
                </g>
              </svg>
            </div>
            <h2>Gold Package</h2>
            <p>All basic package - Includes Payroll and Talent Acquisition</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
