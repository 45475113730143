import React from 'react'


export default function LibraryVideo() {
  return (
    <section className="section-library-anim-vid">
    <video controls={false} muted={true} autoPlay={true} width="100%" loop={true} >
                 <source
                   type="video/mp4"
                   src="./assets/file.mp4"
                 />
               </video>
       </section>
  )
}
