import React from "react";
import logo from "../../assets/img/unimas.webp";
import { Container } from "react-bootstrap";

export default function UnimasIntro() {
  return (
    <section>
      <div className="section-unimas">
        <div className="row">
          <div className="col-6">&nbsp;</div>
          <div className="col-6">
            <div className="section-unimas-bg">
              <div className="container">
                <img src={logo} className="img-fluid" alt="" />
                <h1 className="section-unimas-h1">introduction</h1>
                <Container>
                  <p className="section-unimas-p">
                    The core purposes the Executive Master in Human Resource &
                    Industrial Relations programme are to:
                    <ul>
                      <li>
                        Develop an understanding of theory and practice in the
                        overall human resource management areas;
                      </li>
                      <li>
                        Help students prepare effectively, through broadening
                        their knowledge, skills and ability for a career as
                        human resource specialists;
                      </li>
                      <li>
                        Inculcate a sense of appreciation for innovative
                        thinking in human resource management areas,
                        particularly in understanding organizational theory and
                        its application to organizational development;
                      </li>
                      <li>
                        Develop the ability to sensibly solve employees and
                        organizational issues;
                      </li>
                      <li>
                        Provide a reliable basis for further professional or
                        educational enhancement in the future;
                      </li>
                    </ul>
                    The Executive Masters in Human Resource & Industrial
                    Relations programme provides learners with the opportunity
                    to enhance their skills and knowledge in human resource
                    management and to manage people in organization effectively.
                  </p>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
