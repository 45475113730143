import React from 'react'
import StudioIntro from '../components/Studio/StudioIntro'
import StudioVideoIntro from '../components/Studio/StudioVideoIntro'
import StudioGallery from '../components/Studio/StudioGallery'
import ContactUs from '../components/ContactUs'

export default function Studio() {
  return (
   <>
   <StudioIntro />
   <StudioVideoIntro/>
   <StudioGallery />
   <ContactUs />
   </>
  )
}
