import React from 'react'
import PhotoAlbum from "react-photo-album";
import { galleries } from './Galleries';
// import galleries from "../../assets/img/gallery";

export default function StudioGallery() {

    galleries();
  return (
    <section className='section-studio-gallery'>

    </section>
  )
}
