import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import AffiliateDescription from "../components/AffiliateDescription";

type person = {
  image: string;
  name: string;
  title: string;
  description: string;
  facebook: string;
  twitter: string;
  linkedin: string;
};

export default function Affiliate() {
  const persons: person[] = [
    {
      name: "Dr. Dev Anand",
      title: "Founder & CEO",
      image: "./assets/img/profile/dev.webp",
      description:
        "25 years as an HR practitioner, consultant, adviser, and trainer; providing skills, expertise, and knowledge in HR-related areas areas such as payroll, manpower management, staff development and retention,HR systems integration, compensation and benefits management and many more",
      facebook: "",
      twitter: "",
      linkedin: "",
    },
    {
      name: "HR Lead",
      title: "Sheyma Ranggasamy",
      image: "./assets/img/profile/sheyma.webp",
      description:
        "With 9 years of experience as a HR practitioner, she was exposed to many areas of Human Capital Development, Safety, Health & Environment (SHE) activities which involves shop floor employees’ right up to the Senior Management Team.",
      facebook: "",
      twitter: "",
      linkedin: "",
    },
    {
      name: "Eugene D'Cruz",
      title: "Office Manager",
      image: "./assets/img/profile/eugene.webp",
      description:
        "30 years’ experience in leading the change agenda, streamlining HR processes, content development, capacity building as well as overseeing HR service delivery. He also specializes in transformation, setting up and re-engineering HR functions, talent management and reward systems",
      facebook: "",
      twitter: "",
      linkedin: "",
    },
    {
      name: "Muhhaman Hisham Abdullah",
      title: "Product Manager",
      image: "./assets/img/profile/hisham.webp",
      description:
        "Business Development Consultant with experience in leveraging consultative selling strategies that builds relationships across all target market. '''Deep knowledge of marketing campaign development and implementation of training programs.",
      facebook: "",
      twitter: "",
      linkedin: "",
    },
    {
      name: "Dr. Edwin Varo",
      title: "Tech Lead",
      image: "./assets/img/profile/edwin.webp",
      description:
        "An international consultant in business process transformation and intellectual capital, with 30 years of experience in Organizational Science and Resource Management specializing in Process Excellence, Human Innovation, Leading Change, Succession Planning Initiatives",
      facebook: "",
      twitter: "",
      linkedin: "",
    },
    {
      name: "Dr. Sharmadas Senasi",
      title: "Customer Support Lead",
      image: "./assets/img/profile/sharmadas.webp",
      description:
        "A certify trainer of HRDF, he is also responsible for training, mentoring, counselling and coaching for the hospitality Industry. He has almost 20 years’ experience of extensive Business & Hotel Management, Human Resource Development & Hospitality background",
      facebook: "",
      twitter: "",
      linkedin: "",
    },
    {
        name: "Dr. Mazlan Zainuddin",
        title: "QA Specialist",
        image: "./assets/img/profile/mazlan.webp",
        description:
          "A member of International Professional Manager Association (IPMA, UK) and Certified Trainer from HRDF. He is also responsible for training, consulting, coaching and facilitating activities in the Certified Professional Trainer programme (Train the Trainer)",
        facebook: "",
        twitter: "",
        linkedin: "",
      },
      {
        name: "Udhaiyah Priya Nesasundaram",
        title: "Content Strategist",
        image: "./assets/img/profile/udkabilanaiyah.webp",
        description:
          "Specialized in department development on - Human Resources – recruitment, training & Employee engagement. Well-experienced in Corporate affairs including – Investor relations, digital marketing, Content creation and branding",
        facebook: "",
        twitter: "",
        linkedin: "",
      },
      {
        name: "Kabilan Manon",
        title: "IT Support",
        image: "./assets/img/profile/sharmadas.webp",
        description:
          "This is your Team Member description. Use this space to write a brief description of this person’s role and responsibilities, or add a short bio.",
        facebook: "",
        twitter: "",
        linkedin: "",
      },
  ];

  return (<>
    <section className="section-meet-team">
      <Container>
        <Row>
          {persons.map((p: person) => {
            return (
              <>
                <Col md={6}>
                  <Card className="mt-3 mb-3">
                    <Card.Header>
                      <img src={p.image} className="img-fluid" alt={p.name} />
                    </Card.Header>
                    <Card.Body>
                      <h2>{p.title}</h2>
                      <h1>{p.name}</h1>
                      <p>{p.description}</p>
                    </Card.Body>
                    <Card.Footer>
                      <Row>
                        <Col>
                          <div className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 448 512"
                            >
                              <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                            </svg>
                          </div>
                          <div className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 512 512"
                            >
                              <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                            </svg>
                          </div>

                          <div className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 320 512"
                            >
                              <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                            </svg>
                          </div>
                          <div className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 448 512"
                            >
                              <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                            </svg>
                          </div>
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Card>
                </Col>
              </>
            );
          })}
        </Row>
      </Container>
    </section>
    <AffiliateDescription />
    </>
  );
}
