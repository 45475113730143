import React from 'react'

export default function HomeHelp() {
  return (
    <section className="section11">
    <div className="row">
      <div className="col-4 section11-bg">&nbsp;</div>
      <div className="col-8">
        <div className="container">
          <h1>HOW WE HELP CLIENTS</h1>
          <h2> Monthly Activities and Updates</h2>
          <hr className="solid" />
          <p>
            In-addition to the standard public courses, we also provide
            custom-designed courses or in-house packages specifically
            designed to suit the company's requirement. Feel free to explore
            all the updates and activites that have been conducted by Videv
            Consulting throughout the years
          </p>
          <div className="row">
            <div className="col-2 align-left">
              <button className="btn btn-monthly">Start Now</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}
