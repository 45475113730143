import React from "react";
import sideImg from "../assets/img/2-02.webp";
import logo from "../assets/img/roundlogo.webp";
import { Col, Form, Row } from "react-bootstrap";

export default function ContactUs() {
  return (
    <section className="section12">
      <div className="row">
        <div className="col-6 sec12-left-side">
          <h1>Let's Talk</h1>
          <img className="img-fluid" src={sideImg} />
        </div>
        <div className="col-6 sec12-right-side">
          <div className="head-logo">
            <img className="img-fluid round-logo" src={logo} alt="" />
          </div>
          <div className="container">
          <div className="form">
            <Form>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>First Name *</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                    {/* <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text> */}
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Last Name *</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                  
                  </Form.Group>
                </Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email *</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                   
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Write a message</Form.Label>
                    <Form.Control as="textarea" type="email" placeholder="Enter email" />
                   
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >
                    <hr className="solid"/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >
                    <button className="btn btn-contact-us">
                        Submit
                    </button>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >
                <div className="form-footer">
            <span>videvconsulting@yahoo.com</span>
            <br/>
            <span>+6016-666 0819 | +6017-517 0337</span>
          </div>
                </Col>
              </Row>
            </Form>
          </div>
          
          </div>
          
        </div>
      </div>
    </section>
  );
}
