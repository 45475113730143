import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function UnimasProgramModule() {
  return (
    <section className="section-unimas-prog-mod">
      <Container>
        <Row>
          <Col md={12}>
            <h1>Program Module</h1>
          </Col>
          <Col md={12}>
            <p>
              <ul>
                <li> Module 1 : Organisational Behaviour & Design</li>
                <li>Module 2 : Strategic Human Resource Management</li>
                <li>Module 3 : Strategic Human Resource Planning</li>
                <li>Module 4 : Negotiation & Conflict Resolution</li>
                <li>Module 5 : Integrated Talent Management & Learning</li>
                <li>Module 6 : Industrial Relations Practices</li>
                <li>Module 7 : Law of Discipline & Misconduct</li>
                <li>Module 8 : Strategic Performance Management</li>
                <li>Module 9 : HR Leadership & Change Management</li>
                <li>Module 10 : Legal & Ethical Issues in HR Management</li>
                <li>Module 11 : Malaysian Industrial Law Cases</li>
                <li>Module 12 : Project Paper (Theory & Methodologies)</li>
              </ul>
            </p>
          </Col>
          <Col md={12}>
            <h1>Assessment Method</h1>
          </Col>
          <Col md={12}>
            <p>
              <ul>
                <li>70% Assignments</li>
                <li>30% Final Assessment</li>
              </ul>
            </p>
          </Col>
          <Col md={12}>
            <h1>Duration of Studies</h1>
          </Col>
          <Col md={12}>
            <ul>
              <li>12 Months - 12 Modules</li>
            </ul>
          </Col>

          <Col md={12}>
            <h1>Who Should Apply</h1>
          </Col>
          <Col md={12}>
            <ul>
              <li>Working Professionals in HR field</li>
            </ul>
          </Col>

          <Col md={12}>
            <h1>Entry Requirement</h1>
          </Col>
          <Col md={12}>
            <ul>
              <li>Passed Diploma/Executive Diploma/Professional Diploma with minimum 3 years working experience in related field and at least 30 years of age, or</li>
                <li>Passed Bachelor Degree/Executive Bachelor Degree with minimum 3 years working experience in related field</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
