import React, { ReactChildren, ReactElement, ReactNode } from 'react';
import Header from './Header';
import Footer from './Footer';

type MainTemplateType = {
    children: JSX.Element[] | JSX.Element
  }
  
export default function MainTemplate({ children }:MainTemplateType) {
  return (<>
    <Header/>
    <div>{ children }</div>
    <Footer/>
    </>
  )
}
