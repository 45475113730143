import React from "react";
import sectionImg from "../assets/img/home-img1.webp";

import { Carousel, Card } from "react-bootstrap";
import PhilosolphyList from "../components/Home/PhilosolphyList";
import TrainingScheme from "../components/TrainingScheme";
import ClientList from "../components/Home/ClientList";
import RoundImageGallery from "../components/Home/RoundImageGallery";
import AvailableServices from "../components/Home/AvailableServices";
import FreeQuote from "../components/FreeQuote";
import BrochureSlider from "../components/Home/BrochureSlider";
import ContactUs from "../components/ContactUs";
import HomeIntro from "../components/Home/HomeIntro";
import HomeVision from "../components/Home/HomeVision";
import HomeFigures from "../components/Home/HomeFigures";
import HomeHr from "../components/Home/HomeHr";
import HomeHelp from "../components/Home/HomeHelp";

export default function Home() {
  return (
    <>
      <HomeIntro />
      <HomeVision />
      <PhilosolphyList />
      <TrainingScheme />
      <ClientList />
      <HomeFigures />
      <RoundImageGallery />
      <AvailableServices />
      <HomeHr />
      <section className="section10"></section>
      <FreeQuote />
      <HomeHelp />
      <BrochureSlider />
      <ContactUs />
    </>
  );
}
