import React from 'react'

export default function HomeHr() {
  return (
    <section className="section9">
    <div className="container">
      <h1>
        How Human Resource (HR )<br />
        is Important in a Workforce
      </h1>
      <p>
        Because it is responsible for a diverse range of responsibilities
        connected to running a business, human resources (HR) is an
        essential department for any company that also employs people.
        Employees of high calibre who contribute to an organization's
        success are easier to find and keep on board with the assistance of
        HR personnel. Additionally, HR is responsible for ensuring
        compliance in a variety of ways relating to company, state, and
        federal regulations. The department of human resources contributes
        to the upkeep of a productive, law-abiding, and cheerful staff. The
        many facets of employee recruiting and hiring, as well as training,
        perks, and conflict resolution, are all handled by human resources.
        Depending on the size of a company, human resources may consist of
        only one person or a whole department with a large number of
        employees. Because employees are necessary for the operation of most
        businesses, human resources departments play a crucial role in
        ensuring that workers have complete access to all of the benefits
        they are entitled to, are aware of the policies of the company, and
        are able to perform their duties effectively. During the course of a
        single day, a worker in human resources might draught a job posting,
        examine the applications that have been sent in, organise a
        workplace event, meet with other workers to address a dispute, and
        bring on board a group of new workers.
      </p>
    </div>
  </section>
  )
}
