import React from 'react'

export default function AffiliateDescription() {
  return (
    <section className="section-affiliate-desc">
          <p>We are a team of certified trainers, with various expertise in many industries such as manufacturing, services, education, IT and construction; having hands-on experience in dealing with HR related issues and also capable of bridging both theoritical and pratical aspect of the human resource management</p>

         <video controls={false} muted={true} autoPlay={true} width="100%" >
              <source
                type="video/mp4"
                src="https://video.wixstatic.com/video/11062b_bc016da29c8b4da282f4052c9a538621/1080p/mp4/file.mp4"
              />
            </video>
      
    </section>
  )
}
