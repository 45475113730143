import React from 'react'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import YouTube from 'react-youtube';
export default function AihraScrollVideos() {
  return (
    <section className="section-aihra-scroll-vid">
<OwlCarousel>
{/* https://youtu.be/YqNrg3bT_uM */}
<div className="item">
<YouTube videoId='YqNrg3bT_uM' />
</div>
<div className="item">
<YouTube videoId='YqNrg3bT_uM' />
</div>
<div className="item">
<YouTube videoId='YqNrg3bT_uM' />
</div>
<div className="item">
<YouTube videoId='YqNrg3bT_uM' />
</div>
</OwlCarousel>
    </section>
  )
}
