import axios from "axios";
import React, { useEffect, useState } from "react";
import { companyId, server } from "../../env";
import ReactCSSTransitionGroup from 'react-transition-group';
import { Spinner } from "react-bootstrap";

type HomeData = {
  Title: string;
  SubTitle: string;
  Description: string;
};

export default function HomeIntro() {

  const [datas, setDatas] = useState<HomeData[]>();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    axios.get(server + "/api/content/Home/Intro/" + companyId)
    .then((result) => {
     console.log(result.data.data);
     setDatas(result.data.data as HomeData[]);
     console.log("Converted Value");
     console.log(result.data.data as HomeData[]);
     setLoading(false);
    })
    .catch((error) =>{

    });
  }, []);
  if(loading)
    return (<Spinner animation="grow" variant="primary" />);
  else
  return (
    <section className="section1 animate__animated animate__backInDown animate__delay-2s">
      <div className="banner">
        <h1>{datas![0].Title}</h1>
        <h1>{datas![0].SubTitle}</h1>
        <p>
        {datas![0].Description}
        </p>
        <button className="btn btn-outline-banner">Let's talk</button>
      </div>
    </section>
   
  );
}
