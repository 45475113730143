import React from 'react'
import bgImg from '../../assets/img/aihra-philosolphy-bg.webp';
import { Col, Row } from 'react-bootstrap';

export default function AihraPhilosolphy() {
  return (
    <section className='section-aihra-phil' style={{ backgroundImage: 'url(./assets/img/aihra-philosolphy-bg.webp)'  }}>
        <Row>
            <Col>&nbsp;</Col>
            <Col>
            <h1>Make sure everybody in the company
has great opportunities, has a meaningful impact & is contributing
to the good of society.”
— Larry Page​</h1>
            </Col>
        </Row>
    </section>
  )
}
