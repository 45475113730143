import React,{useState, useEffect} from 'react'
import logo from "../../assets/img/videv.webp";
import axios from 'axios';
import { companyId, server } from '../../env';
import { Spinner } from 'react-bootstrap';

type HomeData = {};

export default function HomeVision() {
  const [datas, setDatas] = useState<HomeData[]>();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    axios.get(server + "/api/content/Home/Intro/" + companyId)
    .then((result) => {
     console.log(result.data.data);
     setDatas(result.data.data as HomeData[]);
     console.log("Converted Value");
     console.log(result.data.data as HomeData[]);
     setLoading(false);
    })
    .catch((error) =>{

    });
  }, []);
  if(loading)
    return (<Spinner animation="grow" variant="primary" />);
  else
  return (
    <section>
    <div className="section2">
      <div className="row">
        <div className="col-6 col-md-8 col-lg-9">&nbsp;</div>
        <div className="col-6 col-md-4 col-lg-3">
          <div className="section2-bg">
            <div className="container">
              <img src={logo} className="img-fluid" alt="" />
              <h1 className="section2-h1">Videv Story</h1>
              <h5 className="section2-h5">
                ViDEV - VISION FOR DEVELOPMENT
                <br />
                AN INNOVATIVE WORKFORCE
              </h5>
              <p className="section2-p">
                Human Resource is an important toll for every organization
                that hires Employees; it’s all about staying ahead of the
                game. Providing excellent consultancy since 2008. we had the
                privilege of helping many great companies do just that. From
                creating HR strategies, Employee Handbook to enhancing IR
                Solutions, Videv aim to drive growth by infusing your firm
                with HR innovation & helps maintain a happy, legal and
                effective workforce.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}
