import React from 'react'
import AppPromo from '../components/AIHRA/AppPromo'
import PackageDetails from '../components/AIHRA/PackageDetails'
import AihraOffer from '../components/AIHRA/AihraOffer';
import WhatAihraOffer from '../components/AIHRA/WhatAihraOffer';
import AihraServices from '../components/AIHRA/AihraServices';
import AihraVideo from '../components/AIHRA/AihraVideo';
import AppSubscription from '../components/AIHRA/AppSubscription';
import AihraAnimationVideo from '../components/AIHRA/AihraAnimationVideo';
import AihraClientList from '../components/AIHRA/AihraClientList';
import AihraPhilosolphy from '../components/AIHRA/AihraPhilosolphy';
import AihraYoutube from '../components/AIHRA/AihraYoutube';
import AihraScrollVideos from '../components/AIHRA/AihraScrollVideos';
import ContactUs from '../components/ContactUs';
import ClientList from '../components/Home/ClientList';

export default function Aihra() {
  return (<>
   <AppPromo />
   <PackageDetails />
   <AihraOffer />
   <WhatAihraOffer/>
   <AihraServices/>
   <AihraVideo/>
   <AppSubscription/>
   <AihraAnimationVideo />
   {/* <AihraClientList /> */}
   <ClientList />
   <AihraPhilosolphy />
   <AihraYoutube />
   <AihraScrollVideos />
   <ContactUs />
   </>
  );
}
