import logo from "./logo.svg";

import "./App.css";
import MainTemplate from "./templates/MainTemplate";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Aihra from "./pages/Aihra";
import Unimas from "./pages/Unimas";
import Affiliate from "./pages/Affiliate";
import Studio from "./pages/Studio";
import Library from "./pages/Library";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <MainTemplate>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="aihra" element={<Aihra />} />
            <Route path="unimas" element={<Unimas />} />
            <Route path="affilate" element={<Affiliate />} />
            <Route path="studio" element={<Studio />} />
            <Route path="library" element={<Library />} />
          </Routes>
        </MainTemplate>
      </BrowserRouter>
    </div>
  );
}

export default App;
