import React from "react";

export default function AihraVideo() {
  return (
    <section className="section-aihra-video">
      <div className="top">
        <div className="container">
          <h1>
            What way's does AiHRA
            <br />
            is useful to your firm
          </h1>
          <p>
            Applications for human resource management are both a current trend
            and the wave of the future. Numerous businesses have already begun
            adapting their HR procedures in order to make room for a Human
            Resources app. These applications not only make information easily
            accessible, but they also assist businesses in lowering their
            operational expenses, raising their operational efficiency, lowering
            their personnel turnover rates, and raising their overall
            productivity.
          </p>
        </div>
      </div>
      <div className="bottom">
        <div className="container">
          <div className="video">
            <video controls width="90%" >
              <source
                type="video/mp4"
                src="https://video.wixstatic.com/video/13e70d_26c7b55465364ef9a3d5da3de164d348/720p/mp4/file.mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </section>
  );
}
