import React from 'react'
import uniImg from '../../assets/img/unimas-bg2.webp';

export default function UnimasUni() {
  return (
    <section className="section-unimas-uni">
        <img src={uniImg} className="img-fluid" alt="Unimas" />
    </section>
  )
}
