import React from 'react'
import UnimasBannerIntro from '../components/Unimas/UnimasBannerIntro'
import UnimasIntro from '../components/Unimas/UnimasIntro'
import UnimasLearningOutcome from '../components/Unimas/UnimasLearningOutcome'
import UnimasProgramModule from '../components/Unimas/UnimasProgramModule'
import UnimasLecturers from '../components/Unimas/UnimasLecturers'
import UnimasOffer from '../components/Unimas/UnimasOffer'
import UnimasClientList from '../components/Unimas/UnimasClientList'
import UnimasUni from '../components/Unimas/UnimasUni'
import UnimasGetinTouch from '../components/Unimas/UnimasGetinTouch'
import UnimasPhotos from '../components/Unimas/UnimasPhotos'
import UnimasDegree from '../components/Unimas/UnimasDegree'
import UnimasAnimation from '../components/Unimas/UnimasAnimation'
import ContactUs from '../components/ContactUs'
import ClientList from '../components/Home/ClientList'

export default function Unimas() {
  return (<>
    <UnimasBannerIntro />
    <UnimasIntro />
    <UnimasLearningOutcome/>
    <UnimasProgramModule/>
    <UnimasLecturers />
    <UnimasOffer />
    {/* <UnimasClientList /> */}
    <ClientList />
    <UnimasUni />
    <UnimasGetinTouch />
    <UnimasPhotos />
    <UnimasDegree />
    <UnimasAnimation />
    <ContactUs />
    </>
  )
}
