import React from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import lec1Img from "../../assets/img/lecturer/lec1.webp";
import lec2Img from "../../assets/img/lecturer/lec2.webp";
import lec3Img from "../../assets/img/lecturer/lec3.webp";
import lec4Img from "../../assets/img/lecturer/lec4.webp";
import lec5Img from "../../assets/img/lecturer/lec5.webp";
import lec6Img from "../../assets/img/lecturer/lec6.webp";
import lec7Img from "../../assets/img/lecturer/lec7.webp";
import lec8Img from "../../assets/img/lecturer/lec8.webp";
import lec9Img from "../../assets/img/lecturer/lec9.webp";

export default function UnimasLecturers() {
  return (
   <section className="section-unimas-lecturer">
<OwlCarousel items={4}>
    <div className="item">
        <img src={lec1Img} className='img' />
    </div>
    <div className="item">
        <img src={lec2Img} className='img' />
    </div>
    <div className="item">
        <img src={lec3Img} className='img' />
    </div>
    <div className="item">
        <img src={lec4Img} className='img' />
    </div>
    <div className="item">
        <img src={lec5Img} className='img' />
    </div>
    <div className="item">
        <img src={lec6Img} className='img' />
    </div>
    <div className="item">
        <img src={lec7Img} className='img' />
    </div>
    <div className="item">
        <img src={lec8Img} className='img' />
    </div>
    <div className="item">
        <img src={lec9Img} className='img' />
    </div>

</OwlCarousel>
   </section>
  )
}
