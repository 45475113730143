import React, {useState, useEffect} from 'react'
// import { Swiper, SwiperSlide } from 'swiper/react';
// // Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/pagination';

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// import required modules
import { Pagination } from 'swiper/modules';
import axios from 'axios';
import { companyId, server } from '../../env';
import { Spinner } from 'react-bootstrap';
type HomeData = {
  Title: string;
  Image: string;
};
export default function RoundImageGallery() {

  const [datas, setDatas] = useState<HomeData[]>();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    axios.get(server + "/api/content/Home/Gallery/" + companyId)
    .then((result) => {
     console.log(result.data.data);
     setDatas(result.data.data as HomeData[]);
     console.log("Converted Value");
     console.log(result.data.data as HomeData[]);
     setLoading(false);
    })
    .catch((error) =>{

    });
  }, []);
  if(loading)
    return (<Spinner animation="grow" variant="primary" />);
  else
  return (
    <>
    <section className='section7'>

    <div className='row-image-holder'>
    {/* <img src="https://static.wixstatic.com/media/13e70d_4da21b2d90f84d869a91a424ccfdf7dc~mv2.jpeg/v1/fill/w_368,h_368,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/13e70d_4da21b2d90f84d869a91a424ccfdf7dc~mv2.jpeg"
      className= 'img-fluid img-round' alt='' />
      <img src="https://static.wixstatic.com/media/13e70d_4da21b2d90f84d869a91a424ccfdf7dc~mv2.jpeg/v1/fill/w_368,h_368,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/13e70d_4da21b2d90f84d869a91a424ccfdf7dc~mv2.jpeg"
      className= ' img-fluid img-round' alt='' />
      <img src="https://static.wixstatic.com/media/13e70d_4da21b2d90f84d869a91a424ccfdf7dc~mv2.jpeg/v1/fill/w_368,h_368,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/13e70d_4da21b2d90f84d869a91a424ccfdf7dc~mv2.jpeg"
      className= ' img-fluid img-round' alt='' />
      <img src="https://static.wixstatic.com/media/13e70d_4da21b2d90f84d869a91a424ccfdf7dc~mv2.jpeg/v1/fill/w_368,h_368,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/13e70d_4da21b2d90f84d869a91a424ccfdf7dc~mv2.jpeg"
      className= ' img-fluid img-round' alt='' /> */}

      <OwlCarousel>
        {datas?.map((item) => {
          return <div className='item'>
          <div className='circular--portrait'>
          <img src={server +"//Image/" + item.Image}
        className= '' alt={item.Title} />
        </div>
          </div>;
        })}
        
        {/* <div className='item'>
        <img src="https://static.wixstatic.com/media/13e70d_4da21b2d90f84d869a91a424ccfdf7dc~mv2.jpeg/v1/fill/w_368,h_368,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/13e70d_4da21b2d90f84d869a91a424ccfdf7dc~mv2.jpeg"
      className= 'img-fluid img-round' alt='' />
        </div>
        <div className='item'>
        <img src="https://static.wixstatic.com/media/13e70d_4da21b2d90f84d869a91a424ccfdf7dc~mv2.jpeg/v1/fill/w_368,h_368,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/13e70d_4da21b2d90f84d869a91a424ccfdf7dc~mv2.jpeg"
      className= 'img-fluid img-round' alt='' />
        </div>
        <div className='item'>
        <img src="https://static.wixstatic.com/media/13e70d_4da21b2d90f84d869a91a424ccfdf7dc~mv2.jpeg/v1/fill/w_368,h_368,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/13e70d_4da21b2d90f84d869a91a424ccfdf7dc~mv2.jpeg"
      className= 'img-fluid img-round' alt='' />
        </div> */}
      </OwlCarousel>
    </div>

    
    </section>
  </>
  )
}
