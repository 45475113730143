import React from 'react'

export default function AihraAnimationVideo() {
  return (
    <section className="section-aihra-anim-vid">
 <video controls={false} muted={true} autoPlay={true} width="100%" >
              <source
                type="video/mp4"
                src="./assets/aihra-service-bg.mp4"
              />
            </video>
    </section>
  )
}
