import React from 'react'
import { Container } from 'react-bootstrap'

export default function UnimasDegree() {
  return (
    <section className="section-unimas-degree">
        <Container>
            <h1>WHY a Masters Degree is
Important for Malaysian Working Adults </h1>
<p>A bachelor's degree is still significant in today's society; however, as a result of the phenomena known as "degree inflation," it is no longer considered to be a sign of extraordinary academic skill. Many individuals are of the opinion that in order to distinguish oneself from one's contemporaries and advance one's professional standing, a master's degree is the very minimum requirement.
<br/><br/>
​

It is common for certain job pathways to demand a master's degree from candidates, though this is not always the case. They are typically required as well if you want to earn a PhD degree in a given field. If you are interested in changing careers but lack the experience necessary to do so, returning to school to earn a master's degree can be an excellent method to gain that experience and help you make the transition.</p>
        </Container>

    </section>
  )
}
