import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import pic1 from '../../assets/img/studio-pic1.webp';

export default function StudioIntro() {
  return (
   <section className='section-studio-intro'>
    <Row>
    <Col md={5}>
<img className='img' src={pic1}  alt='intro'/>
    </Col>
    <Col md={7}>
        <Container>
            <h1>Our Gallery</h1>
            <p> HOW WE HELP CLIENTS! A variety of programs and training, tailored to your requirement. Our programs cover a wide range of topics with the aid of various experts and industrial leaders to bring your team to the next level</p>
        </Container>
    </Col>
    </Row>
   </section>
  )
}
