import React from "react";
import { Card, Carousel, Col, Container, Row } from "react-bootstrap";
import aihraRound from "../../assets/img/aihra-round.webp";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import pic1 from "../../assets/img/Picture1.webp";
import pic2 from "../../assets/img/Picture3.webp";
import pic3 from "../../assets/img/Picture2.webp";
import ios from "../../assets/img/appstore.webp";
import android from "../../assets/img/android.webp";

export default function AppPromo() {
  return (
    <section className="app-promo">
      <Container>
        <Row>
          <Col>
            <Row>
              <Col className="app-promo-left" md={12} sm={6}>
                <img className="img-fluid" src={aihraRound} alt="Aihra Logo" />
                <h1>One App, Many Uses</h1>
                <p>
                  Automated & Integrated Human Resource Applications, AiHRA
                  represents a new generation of HR and is an essential part of
                  the management puzzle. It assists businesses in keeping up
                  with the demands of the modern workplace and in expanding
                  their operations.
                </p>
                <Row>
                    <Col>
                    <img className="img-fluid" src={ios} alt="ios" />
                    </Col>
                    <Col>
                    <img className="img-fluid" src={android} alt="android" />
                    </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col>
          <Card>
            <Card.Body>
                <Carousel data-bs-theme="dark" controls indicators>
                    <Carousel.Item>
                    <img className="img-fluid" src={pic1} alt=""/>
                    </Carousel.Item>
                    <Carousel.Item>
                    <img className="img-fluid" src={pic2} alt=""/>
                    </Carousel.Item>
                    <Carousel.Item>
                    <img className="img-fluid" src={pic3} alt=""/>
                    </Carousel.Item>
                </Carousel>
        
          </Card.Body>
          </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
