import React, { useEffect, useState } from "react";
import { companyId, server } from "../../env";
import axios from "axios";
import { Spinner } from "react-bootstrap";

type philosolphy = {
  Title: string;
  Content: string;
};

export default function PhilosolphyList() {
  // const pholosolphies: philosolphy[] = [
  //   {
  //     title: "Creative",
  //     description:
  //       "To instill creativity in learning and training, Being creative gives you the ability to examine circumstances from a variety of angles, come up with original answers to difficult questions, and provide artistic and original content for the projects you work on in both your personal and professional life.",
  //   },
  //   {
  //     title: "Creative",
  //     description:
  //       "To instill creativity in learning and training, Being creative gives you the ability to examine circumstances from a variety of angles, come up with original answers to difficult questions, and provide artistic and original content for the projects you work on in both your personal and professional life.",
  //   },
  //   {
  //     title: "Creative",
  //     description:
  //       "To instill creativity in learning and training, Being creative gives you the ability to examine circumstances from a variety of angles, come up with original answers to difficult questions, and provide artistic and original content for the projects you work on in both your personal and professional life.",
  //   },
  //   {
  //     title: "Creative",
  //     description:
  //       "To instill creativity in learning and training, Being creative gives you the ability to examine circumstances from a variety of angles, come up with original answers to difficult questions, and provide artistic and original content for the projects you work on in both your personal and professional life.",
  //   },
  //   {
  //     title: "Creative",
  //     description:
  //       "To instill creativity in learning and training, Being creative gives you the ability to examine circumstances from a variety of angles, come up with original answers to difficult questions, and provide artistic and original content for the projects you work on in both your personal and professional life.",
  //   },
  //   {
  //     title: "Creative",
  //     description:
  //       "To instill creativity in learning and training, Being creative gives you the ability to examine circumstances from a variety of angles, come up with original answers to difficult questions, and provide artistic and original content for the projects you work on in both your personal and professional life.",
  //   },
  // ];
  const [datas, setDatas] = useState<philosolphy[]>();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    axios.get(server + "/api/content/Home/Offer/" + companyId)
    .then((result) => {
     console.log(result.data.data);
     setDatas(result.data.data as philosolphy[]);
     console.log("Converted Value");
     console.log(result.data.data as philosolphy[]);
     setLoading(false);
    })
    .catch((error) =>{

    });
  }, []);
  if(loading)
    return (<Spinner animation="grow" variant="primary" />);
  else
  return (
    <section className="section3">
      <h1 className="section3-title">What ViDEV Offer</h1>
      <h2 className="section3-title2">Emphasis on 4'C' Philosophy</h2>
      <div className="container">
        <div className="row">
          <div className="col-12 philosolpy-list">
            {datas?.map((item: philosolphy) => {
              return (
                <>
                  <div className="row philosolpy">
                    <div className="col-12">
                        <hr className="solid line" />
                    </div>
                    <div className="col-12">
                        <div className="row">
                        <div className="col-1 icon-center">
                      <svg
                        className="icon-list"
                        preserveAspectRatio="none"
                        data-bbox="29.5 29.5 141 141"
                        viewBox="29.5 29.5 141 141"
                        height="200"
                        width="200"
                        xmlns="http://www.w3.org/2000/svg"
                        data-type="shape"
                        role="presentation"
                        aria-hidden="true"
                        aria-label=""
                      >
                        <g>
                          <path d="M170.5 93.92v6.118c-.022 38.861-31.646 70.462-70.5 70.462h-.04c-38.874-.022-70.481-31.667-70.46-70.54.022-38.859 31.646-70.46 70.5-70.46h.04a70.046 70.046 0 0 1 28.653 6.104 3.998 3.998 0 0 1 2.025 5.281 3.997 3.997 0 0 1-5.281 2.025 62.121 62.121 0 0 0-25.401-5.41h-.035c-34.448 0-62.48 28.013-62.501 62.464-.02 34.463 28.002 62.516 62.464 62.536H100c34.447 0 62.479-28.013 62.5-62.464V93.92a4 4 0 0 1 8 0zm-87.622-3.294a4 4 0 1 0-5.656 5.656l19.95 19.95a4.001 4.001 0 0 0 2.828 1.172h.001a3.997 3.997 0 0 0 2.829-1.173l66.5-66.566a4 4 0 0 0-.003-5.657 3.999 3.999 0 0 0-5.657.003l-63.671 63.735-17.121-17.12z"></path>
                        </g>
                      </svg>
                    </div>
                    <div className="col-5">
                      <div className="highlight-line">{ item.Title }</div>
                    </div>
                    <div className="col-6">
                      <p className="line-detail">
                      { item.Content }
                      </p>
                    </div>
                        </div>
                    </div>
                   
                  </div>
                </>
              );
            })}
          </div>
          <div className="col-12">
            <hr className="solid line"/>
          </div>
        </div>
      </div>
    </section>
  );
}
