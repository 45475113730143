import React from 'react'

export default function UnimasAnimation() {
    //https://video.wixstatic.com/video/11062b_00a8c83765b041e99f229f795e93bc7e/1080p/mp4/file.mp4
  return (
   <section>
     <video controls={false} muted={true} autoPlay={true} width="100%" >
              <source
                type="video/mp4"
                src="https://video.wixstatic.com/video/11062b_00a8c83765b041e99f229f795e93bc7e/1080p/mp4/file.mp4"
              />
            </video>
   </section>
  )
}
