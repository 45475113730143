import React from 'react'

export default function TrainingScheme() {
  return (
    <section className="section4">
    <div className="section4-bg">
        <div className='row'>
            <div className='col-6 col-xs-11 col-sm-6 col-md-4 col-lg-4 c-col'>
      <div className="mid-box">
        <div className="container">
          <h1>ViDEV</h1>
          <h2>TRAINING SCHEME</h2>

          <span>UNDER THE HRDF</span>
          <p>
            All training programs under Videv Consulting have been granted
            Approved Training Programme (ATP) under Human Resource
            Development Act 1992 - SBL Scheme
          </p>
          <button className="btn btn-box">Start Now</button>
        </div>
      </div>
      </div>
    </div>
    </div>
  </section>
  )
}
