import React from 'react';
import aihraOffer from "../../assets/img/aihra-offer.webp";

export default function AihraOffer() {
  return (
   <section className="aihra-offer">
    <img className="full-image" src={aihraOffer} />
   </section>
  )
}
