import React, { useEffect, useRef, useState } from 'react'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from 'axios';
import { companyId, server } from '../../env';
import { Spinner } from 'react-bootstrap';
type SliderData = {
    Brochure: string;
    Title: string;

};

export default function BrochureSlider() {
    const [scrollAmount, setScrollAmount] = useState(0);

    const [width, setWidth] = useState(0);



const containerRef = useRef(null);

useEffect(() => {

    if(containerRef!= null && containerRef.current != null)
    setWidth((containerRef.current as HTMLDivElement).offsetWidth?? 0);
},[containerRef.current]);

function btnNext(){
    setScrollAmount((prevAmount) => prevAmount - (width/3));
}

const handlePrevClick = () => {
    setScrollAmount((prevAmount) => prevAmount + 300);
  };

  const handleNextClick = () => {
    setScrollAmount((prevAmount) => prevAmount - 300);
  };

  const [datas, setDatas] = useState<SliderData[]>();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    axios.get(server + "/api/content/Home/Brochure/" + companyId)
    .then((result) => {
     console.log(result.data.data);
     setDatas(result.data.data as SliderData[]);
     console.log("Converted Value");
     console.log(result.data.data as SliderData[]);
     setLoading(false);
    })
    .catch((error) =>{

    });
  }, []);
  if(loading)
    return (<Spinner animation="grow" variant="primary" />);
  else
  return (
  
<section className='section-home-brochure'>
<OwlCarousel dots={false}>
{ datas?.map((bro) => {
    return <div className='brochure-item'>
    <img src={ server+ "/Image/" + bro.Brochure} alt={bro.Title} />
    </div>
}) }

</OwlCarousel>
</section>
  )
}
